import React, {useCallback, useState} from 'react';
import {useForm} from "antd/lib/form/Form";
import {GameFamilyDto, GameTypeDto} from "./types";
import {useQuery} from "react-query";
import axios, {AxiosResponse} from "axios";
import {getAuthHeaders} from "../admin/auth";
import {
    Button,
    Descriptions,
    Divider,
    Flex,
    Form,
    Input,
    List,
    message,
    Modal,
    Popconfirm,
    Spin,
    Typography
} from "antd";
import {defaultFormItemLayout} from "../commons/forms";
import {PendingDataGuard} from "../commons/Indicators";
import {DeleteFilled, EditOutlined} from "@ant-design/icons";
import AddButton from "../commons/AddButton";
import DescriptionsItem from "antd/es/descriptions/Item";

export const GameFamiliesAdministration: React.FC = () => {
    const [form] = useForm<GameFamilyDto>();
    const [editing, setEditing] = useState<boolean>(false);
    const [working, setWorking] = useState<boolean>(false);

    const {data: types, isLoading: isLoadingTypes, isError: isErrorTypes} = useQuery({
        queryFn: () => axios.get<object, AxiosResponse<GameTypeDto[]>>('/api/games/types')
            .then(r => r.data),
        queryKey: ['game-types']
    });
    const {data: families, isLoading: isLoadingFamilies, isError: isErrorFamilies, refetch} = useQuery({
        queryKey: ['game-families'],
        queryFn: () => axios.get<object, AxiosResponse<GameFamilyDto[]>>(`/api/admin/game-families`, {
            headers: {...getAuthHeaders()}
        }).then(r => r.data)
    });

    const doSubmit = useCallback((values: GameFamilyDto) => {
        setWorking(true);
        const request = !values.id
            ? axios.post<object, AxiosResponse<GameFamilyDto>>(`/api/admin/game-families`, values,{
                headers: {...getAuthHeaders()}
            })
            : axios.put<object, AxiosResponse<GameFamilyDto>>(`/api/admin/game-families/${values.id}`, values, {
                headers: {...getAuthHeaders()}
            });

        request
            .then(() => {
                refetch();
                message.success('Changes made!')
                form.resetFields();
                setEditing(false);
            })
            .catch(() => message.error('Failed to make changes. Please try again later...'))
            .finally(() => setWorking(false));
    }, [setWorking, setEditing, refetch]);

    const doDelete = useCallback((record: GameFamilyDto) => {
        setWorking(true);
        axios.delete<object, AxiosResponse<void>>(`/api/admin/game-families/${record.id}`,{
            headers: {...getAuthHeaders()}
        })
            .then(() => {
                refetch();
                message.success('Changes made!')
                setEditing(false);
            })
            .catch(() => message.error('Failed to make changes. Please try again later...'))
            .finally(() => setWorking(false));
    }, [setWorking, setEditing, refetch]);

    return (
        <PendingDataGuard data={types && families} isLoading={isLoadingTypes || isLoadingFamilies} isError={isErrorTypes || isErrorFamilies}>
            {working && <Spin fullscreen tip={'Working...'} />}
            <Modal open={editing} closable destroyOnClose={true} onCancel={() => {
                setEditing(false);
                form.resetFields();
            }} footer={null}>
                <div style={{height: '25px'}} />
                <Form form={form} {...defaultFormItemLayout} onFinish={doSubmit} onValuesChange={() => form.validateFields({dirty: true})}>
                    <Form.Item name={'id'} hidden />
                    <Form.Item name={'name'} label={'Name'} required rules={[{required:true}]}>
                        <Input />
                    </Form.Item>
                </Form>
                <Flex vertical={false} justify={'end'}>
                    <Button type={"primary"} onClick={form.submit}>Submit</Button>
                </Flex>
            </Modal>
            <Divider />
            <List
                size={"small"}
                header={<AddButton onSave={() => setEditing(true)} />}
                dataSource={(families || [])}
                renderItem={(family) =>
                    <>
                        <Descriptions size={"small"} bordered title={<Typography.Text strong>{family.name}</Typography.Text>}>
                            <DescriptionsItem label={<Typography.Text strong>Actions</Typography.Text>}>
                                <Flex vertical={false} justify={'space-evenly'}>
                                    <Button type={"primary"} icon={<EditOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} onClick={() => {
                                        setEditing(true);
                                        form.resetFields();
                                        form.setFieldsValue(family);
                                    }}/>} />
                                    <Popconfirm title={'Are you sure you want to delete this game family?'} onConfirm={() => doDelete(family)}>
                                        <Button danger icon={<DeleteFilled onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />} />
                                    </Popconfirm>
                                </Flex>
                            </DescriptionsItem>
                        </Descriptions>
                        <Divider />
                    </>
                }
            />
        </PendingDataGuard>
    )
}