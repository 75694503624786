import React, {useCallback, useMemo, useState} from 'react';
import {useQuery} from "react-query";
import axios, {AxiosResponse} from "axios";
import {GameDto, GameFamilyDto, GameTypeDto} from "./types";
import {PendingDataGuard} from "../commons/Indicators";
import CheckableTag from "antd/lib/tag/CheckableTag";
import {
    Button,
    Descriptions,
    Divider,
    Empty,
    Flex,
    Form,
    Image,
    Input,
    InputNumber,
    List,
    message,
    Modal,
    Popconfirm,
    Spin,
    Typography
} from "antd";
import {getAuthHeaders} from "../admin/auth";
import {DeleteFilled, EditOutlined} from "@ant-design/icons";
import {useForm} from "antd/lib/form/Form";
import {defaultFormItemLayout} from "../commons/forms";
import {GameTypeSelector} from "./GameTypeSelector";
import {GameFamilySelector} from "./GameFamilySelector";
import TextArea from "antd/es/input/TextArea";
import {Link} from "react-router-dom";
import DescriptionsItem from "antd/es/descriptions/Item";
import AddButton from "../commons/AddButton";

export const GamesAdministration = () => {
    const [form] = useForm<GameDto>();
    const [editing, setEditing] = useState<boolean>(false);
    const [working, setWorking] = useState<boolean>(false);
    const [gameType, setGameType] = React.useState<GameTypeDto['tag']|undefined>();
    const typeParam = useMemo<string>(() => gameType ? `?type=${gameType}` : '', [gameType]);

    const {data: types, isLoading: isLoadingTypes, isError: isErrorTypes} = useQuery({
        queryFn: () => axios.get<object, AxiosResponse<GameTypeDto[]>>('/api/games/types')
            .then(r => r.data),
        queryKey: ['game-types']
    });
    const {data: families, isLoading: isLoadingFamilies, isError: isErrorFamilies} = useQuery({
       queryKey: ['game-families'],
       queryFn: () => axios.get<object, AxiosResponse<GameFamilyDto[]>>('/api/admin/game-families', {
           headers: {...getAuthHeaders()}
       }).then(r => r.data),
       refetchInterval: 5000,
       refetchIntervalInBackground: true,
    });
    const {data: games, isLoading: isLoadingGames, isError: isErrorGames, refetch } = useQuery(
        ['games', typeParam],
        () => axios.get<object, AxiosResponse<GameDto[]>>(`/api/admin/games${typeParam}`, {
            headers: {...getAuthHeaders()}
        }).then(r => r.data),
    );

    const doSubmit = useCallback((values: GameDto) => {
        setWorking(true);
        const request = !values.id
            ? axios.post<object, AxiosResponse<GameDto>>(`/api/admin/games`, values,{
                headers: {...getAuthHeaders()}
            })
            : axios.put<object, AxiosResponse<GameDto>>(`/api/admin/games/${values.id}`, values, {
                headers: {...getAuthHeaders()}
            });

        request
            .then(() => {
            refetch();
            message.success('Changes made!')
            form.resetFields();
            setEditing(false);
        })
        .catch(() => message.error('Failed to make changes. Please try again later...'))
        .finally(() => setWorking(false));
    }, [setWorking, setEditing, refetch]);

    const doDelete = useCallback((record: GameDto) => {
        setWorking(true);
        axios.delete<object, AxiosResponse<GameDto[]>>(`/api/admin/games/${record.id}`,{
            headers: {...getAuthHeaders()}
        })
        .then(() => {
            refetch();
            message.success('Changes made!')
            setEditing(false);
        })
        .catch(() => message.error('Failed to make changes. Please try again later...'))
        .finally(() => setWorking(false));
    }, [setWorking, setEditing, refetch]);

    return (
        <PendingDataGuard data={types && families} isLoading={isLoadingTypes || isLoadingFamilies} isError={isErrorTypes || isErrorFamilies}>
            {working && <Spin fullscreen tip={'Working...'} />}
            <Modal open={editing} closable destroyOnClose={false} onCancel={() => {
                setEditing(false);
                form.resetFields();
            }} footer={null}>
                <div style={{height: '25px'}} />
                <Form form={form} {...defaultFormItemLayout} onFinish={doSubmit} onValuesChange={() => form.validateFields({dirty: true})}>
                    <Form.Item name={'id'} hidden />
                    <Form.Item name={'type'} label={'Game Type'} required rules={[{required:true}]} >
                       <GameTypeSelector allTypes={(types || [])} />
                    </Form.Item>
                    <Form.Item name={'title'} label={'Title'} required rules={[{required:true}]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name={'description'} label={'Description'} required rules={[{required:true}]}>
                        <TextArea rows={5} showCount maxLength={500} />
                    </Form.Item>
                    <Form.Item name={'family'} label={'Game Family'}>
                        <GameFamilySelector allFamilies={(families || [])}/>
                    </Form.Item>
                    <Form.Item name={'releaseYear'} label={'Release Year'}>
                        <InputNumber minLength={4} maxLength={4} min={1900}/>
                    </Form.Item>
                    <Form.Item name={'moreInfo'} label={'More Info (URL)'} rules={[{type: 'url'}]} hasFeedback>
                        <Input inputMode={"url"} />
                    </Form.Item>
                    <Form.Item name={'mediaUrl'} label={'Media (URL)'} rules={[{type: 'url'}]} hasFeedback>
                        <Input inputMode={"url"} />
                    </Form.Item>
                </Form>
                <Flex vertical={false} justify={'end'}>
                    <Button type={"primary"} onClick={form.submit}>Submit</Button>
                </Flex>
            </Modal>
            <CheckableTag checked={!gameType} onClick={() => setGameType(undefined)}>All</CheckableTag>
            {(types || []).map(type => <CheckableTag checked={gameType===type.tag} onClick={() => setGameType(type.tag)}>{type.name}</CheckableTag>)}
            <PendingDataGuard data={games} isLoading={isLoadingGames} isError={isErrorGames}>
                <List
                    header={<AddButton onSave={() => {
                        form.resetFields();
                        setEditing(true);
                    }} />}
                    size={"small"}
                    dataSource={(games || [])}
                    renderItem={(game) =>
                        <>
                            <Descriptions bordered size={"small"} title={game.title} layout={"vertical"} >
                                <DescriptionsItem label={<Typography.Text strong>Media URL</Typography.Text>} style={{maxWidth: '100vw'}}>
                                    {game.mediaUrl ?
                                        <Image key={game.mediaUrl} src={game.mediaUrl} />
                                        : <Empty />
                                    }
                                </DescriptionsItem>
                                <DescriptionsItem label={<Typography.Text strong>Description</Typography.Text>} style={{maxWidth: '100vw'}}>
                                    <Typography.Paragraph  ellipsis={{
                                        tooltip: 'click to expand',
                                        expandable: true,
                                    }} style={{width: 200}} >{game.description}</Typography.Paragraph>
                                </DescriptionsItem>
                                <DescriptionsItem label={<Typography.Text strong>Family</Typography.Text>} style={{maxWidth: '100vw'}}>
                                    <Typography.Paragraph>{game.family?.name}</Typography.Paragraph>
                                </DescriptionsItem>
                                <DescriptionsItem label={<Typography.Text strong>Release Year</Typography.Text>} style={{maxWidth: '100vw'}}>
                                    <Typography.Paragraph>{game.releaseYear}</Typography.Paragraph>
                                </DescriptionsItem>
                                <DescriptionsItem label={<Typography.Text strong>Type</Typography.Text>} style={{maxWidth: '100vw'}}>
                                    <Typography.Paragraph>{game.type.name}</Typography.Paragraph>
                                </DescriptionsItem>
                                <DescriptionsItem label={<Typography.Text strong>More Info</Typography.Text>} style={{maxWidth: '100vw'}}>
                                    <Typography.Paragraph>{game.moreInfo ? <Link target={"_blank"} to={game.moreInfo}>More Info</Link> : 'N/A'}</Typography.Paragraph>
                                </DescriptionsItem>
                                <DescriptionsItem label={<Typography.Text strong>Actions</Typography.Text>} style={{maxWidth: '100vw'}}>
                                    <Flex vertical={false} justify={'space-evenly'}>
                                        <Button type={"primary"} icon={<EditOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} onClick={() => {
                                            form.setFieldsValue(game);
                                            setEditing(true);
                                        }}/>} />
                                        <Popconfirm title={'Are you sure you want to delete this game?'} onConfirm={() => doDelete(game)}>
                                            <Button danger icon={<DeleteFilled onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />} />
                                        </Popconfirm>
                                    </Flex>
                                </DescriptionsItem>
                            </Descriptions>
                            <Divider />
                        </>
                    }
                />
            </PendingDataGuard>
        </PendingDataGuard>
    )
}