import React, {useCallback} from 'react';
import {GameFamilyDto} from "./types";
import {Select} from "antd";

export type GameTypeSelectorProps = {
    value?: GameFamilyDto,
    onChange?: (value: GameFamilyDto | undefined) => void;
    allFamilies: GameFamilyDto[];
}

export const GameFamilySelector: React.FC<GameTypeSelectorProps> = (props) => {
    const {value, onChange, allFamilies} = props;
    const internalOnChange = useCallback((changed: string | null) => {
        if (!onChange) {
            return;
        }
        const externalValue = allFamilies.find((family) => family.id === changed);
        onChange(externalValue);
    }, [allFamilies])

    return (
        <Select value={value?.id} onChange={internalOnChange}>
            <Select.Option key={'none'} value={undefined}>N/A</Select.Option>
            {allFamilies.map((family) => <Select.Option key={family.id} value={family.id}>{family.name}</Select.Option>)}
        </Select>
    );
}