import React, {useCallback, useMemo} from 'react';
import dayjs, {Dayjs} from "dayjs";
import {DatePicker, TimePicker} from "antd";

export type MobileDatePickerProps = {
    value?: string | Dayjs | undefined;
    onChange?: (value: string | Dayjs | undefined) => void;
}

export const MobileDatePicker: React.FC<MobileDatePickerProps> = (props) => {
    const {value, onChange} = props;

    const internalValue = useMemo(() => {
        return dayjs(value);
    }, [value]);

    const internalOnChange = useCallback((value: any ) => {
        const normalizedValue = value ? dayjs(value) : undefined;
        if (onChange) {
            onChange(normalizedValue);
        }
    }, [onChange]);

    return (
        <>
            <DatePicker format={'YYYY-MM-DD'} size={"small"} value={internalValue} onChange={internalOnChange} />
            <TimePicker format={'HH:mm'} size={"small"} showSecond={false} minuteStep={15 as any} value={internalValue} onChange={internalOnChange} />
        </>
    )
}