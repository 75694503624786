import React, {useCallback} from 'react';
import {GameTypeDto} from "./types";
import {Select} from "antd";

export type GameTypeSelectorProps = {
    value?: GameTypeDto,
    onChange?: (value: GameTypeDto | undefined) => void;
    allTypes: GameTypeDto[];
}

export const GameTypeSelector: React.FC<GameTypeSelectorProps> = (props) => {
    const {value, onChange, allTypes} = props;
    const internalOnChange = useCallback((changed: string | null) => {
        if (!onChange) {
            return;
        }
        const externalValue = allTypes.find((type) => type.tag === changed);
        onChange(externalValue);
    }, [allTypes])

    return (
        <Select value={value?.tag} onChange={internalOnChange}>
            {allTypes.map((type) => <Select.Option key={type.tag} value={type.tag}>{type.name}</Select.Option>)}
        </Select>
    );
}