import React, {useCallback, useMemo} from 'react';
import {GameDto} from "./types";
import {Select} from "antd";

export type GameSelectorProps = {
    value?: string,
    onChange?: (value: string | undefined) => void;
    allGames: GameDto[] | undefined;
    loading?: boolean;
}

export const GameSelector: React.FC<GameSelectorProps> = (props) => {
    const {value, onChange, allGames, loading} = props;
    const normalisedGames = useMemo<GameDto[]>(() => allGames || [], [allGames]);
    const internalOnChange = useCallback((changed: string | null) => {
        if (!onChange) {
            return;
        }
        const externalValue = normalisedGames.find((game) => game.id === changed);
        onChange(externalValue?.id);
    }, [normalisedGames])

    return (
        <Select value={value} onChange={internalOnChange} loading={loading}>
            {normalisedGames.map((game) => <Select.Option key={game.id} value={game.id}>{game.title}</Select.Option>)}
        </Select>
    );
}