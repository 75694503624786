import React from 'react';
import {CommonLayout} from "../commons/CommonLayout";
import {useQuery} from "react-query";
import axios, {AxiosResponse} from "axios";
import {PublicEventDTO} from "./types";
import './home.css'
import {Heading} from "../commons/translations/Heading";
import {EventCard} from "./EventCard";
import {PendingDataGuard} from "../commons/Indicators";
import {Divider} from "antd";

export const Home = () => {

    const {isLoading, isError, data: upcoming} = useQuery(`upcoming-events`,
        () => axios.get<any,AxiosResponse<PublicEventDTO[]>>(`/api/events` ).then(r => r.data));

    return (
        <CommonLayout>
            <PendingDataGuard isLoading={isLoading} isError={isError} data={upcoming}>
                <HomePage upcoming={upcoming!} />
            </PendingDataGuard>
        </CommonLayout>
    )
};

const HomePage: React.FC<{upcoming: PublicEventDTO[]}> = (props) => {
    const {upcoming} = props;
    return (
        <>
            <Heading level={5} trl={'home.greeting'}/>
            {upcoming.map((event: PublicEventDTO) => <>
                    <EventCard event={event} />
                    <Divider />
                </>
            )}
        </>
    )
}