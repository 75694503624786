import React, {useCallback, useState} from 'react';
import {useQuery} from "react-query";
import axios, {AxiosResponse} from "axios";
import {Button, Descriptions, Empty, List, Modal, Popconfirm, Select, Typography} from "antd";
import {PublicEventDTO} from "../home/types";
import {ReservationDTO} from "./types";
import {Divider} from "antd/lib";
import {getAuthHeaders} from "../admin/auth";
import DescriptionsItem from "antd/es/descriptions/Item";

export const Reservations: React.FC = (_) => {

    const [event, setEvent] = useState<string | undefined>(undefined);
    const {isLoading: isLoadingEvents, data: upcoming} = useQuery(`upcoming-events`,
        () => axios.get<any,AxiosResponse<PublicEventDTO[]>>(`/api/events` ).then(r => r.data));
    const {isLoading: isLoadingReservations, data: reservations, refetch} = useQuery(`event-reservations-${event}`,
        () => axios.get<any,AxiosResponse<ReservationDTO[]>>(`/api/admin/reservations/${event}`, {
            headers: {...getAuthHeaders()}
        }).then(r => r.data), {
            enabled: !!event
        })
    const cancelReservation = useCallback((reservationId: string) => {
        axios.delete(`/api/admin/reservations/${reservationId}`, {
            headers: {...getAuthHeaders()}
        })
            .then(() => refetch())
            .catch(() => Modal.error({
                title: 'Error',
                content: 'We could not cancel this reservation, please try again later.'
            }));
    }, [refetch])

    return (
        <div style={{height: '100%'}}>
            <Typography.Text>Please select an event</Typography.Text>
            <br/>
            <Select style={{width: '100%'}} onSelect={setEvent}>
                {(upcoming || []).map((evt: any) => <Select.Option value={evt.id}>{evt.title}</Select.Option>)}
            </Select>
            {!!event && reservations && reservations.length > 0 &&
                <List
                    size={"small"}
                    dataSource={(reservations || [])}
                    loading={isLoadingEvents || isLoadingReservations}
                    renderItem={(reservation =>
                        <>
                            <Divider />
                            <Descriptions size={"small"} bordered title={reservation.name} layout={"vertical"}>
                                <DescriptionsItem label={<Typography.Text strong>Seats</Typography.Text>}>
                                    <Typography.Text>{reservation.seats}</Typography.Text>
                                </DescriptionsItem>
                                <DescriptionsItem label={<Typography.Text strong>Code</Typography.Text>}>
                                    <Typography.Text copyable >{reservation.code}</Typography.Text>
                                </DescriptionsItem>
                                <DescriptionsItem label={<Typography.Text strong>Game</Typography.Text>}>
                                    <Typography.Text>{reservation.game?.title}</Typography.Text>
                                </DescriptionsItem>
                                <DescriptionsItem label={<Typography.Text strong>Actions</Typography.Text>}>
                                    <Popconfirm
                                        title={`Are you sure you want to cancel ${reservation.seats} reserved seats by ${reservation.name}?`}
                                        onConfirm={() => {
                                            cancelReservation(reservation.id);
                                        }}>
                                        <Button type={"link"} style={{color: 'red'}} >Cancel</Button>
                                    </Popconfirm>
                                </DescriptionsItem>
                            </Descriptions>
                        </>
                    )}
                />
            }
            {!!event && reservations && reservations.length === 0 &&
                <>
                    <Divider />
                    <Empty />
                </>
            }
        </div>
    )
}