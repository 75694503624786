import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router";
import {Guarded} from "../admin/Guarded";
import {
    Button,
    Descriptions,
    Divider,
    Flex,
    Form,
    Image,
    Input,
    InputNumber,
    Layout,
    List,
    message,
    Modal,
    Typography
} from "antd";
import {defaultFormItemLayout} from "../commons/forms";
import TextArea from "antd/es/input/TextArea";
import {useForm} from "antd/lib/form/Form";
import {useQuery} from "react-query";
import {getAuth, getAuthHeaders} from "../admin/auth";
import AddButton from "../commons/AddButton";
import {GameCrudDto} from "./types";
import axios, {AxiosResponse} from "axios";
import {Popconfirm} from 'antd/lib';
import {GameDto} from "../games/types";
import {GameSelector} from "../games/GameSelector";
import DescriptionsItem from "antd/es/descriptions/Item";

export const TablesEditor: React.FC = () => {
    const { event } = useParams();
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [form] = useForm<GameCrudDto | undefined>(undefined);
    const [editableTable, setEditableTable] = useState<GameCrudDto | undefined>(undefined);
    const {isLoading, data, refetch} = useQuery<any,any,GameCrudDto[],any>(
        `${'lodge-admin-events'}-${event}`,
        () => axios.get(`/api/admin/tables/${event}`, {
            headers: {...getAuthHeaders()}
        }).then(r => r.data), {
            enabled: (!!getAuth() && !!getAuth().token)
        }
    )

    useEffect(() => {
        if (!!editableTable) {
            form.setFieldsValue({...editableTable});
        }
    }, [editableTable, form]);

    const submitChanges = useCallback((data: GameCrudDto) => {
        const request = (!editableTable)
            ? () => axios.post(`/api/admin/tables/${event}`, data, {
                headers: {...getAuthHeaders()}
            })
            : () => axios.put(`/api/admin/tables/${editableTable?.id}`, data, {headers: {...getAuthHeaders()}});
        request()
            .then(() => {
                setEditableTable(undefined);
                form.resetFields();
                setIsModalOpen(false);
                message.success('Job done!');
                refetch();
            })
            .catch(() => {
                message.error('Failed to complete work, please try again.');
                setEditableTable(undefined);
            });
    }, [event, form, editableTable, setEditableTable, refetch]);

    const editTable = useCallback((table: GameCrudDto) => {
        setEditableTable(table);
        setIsModalOpen(true);
    }, [setEditableTable, setIsModalOpen]);

    const createNewTable = useCallback(() => {
        setEditableTable(undefined);
        setIsModalOpen(true);
    }, [setEditableTable, setIsModalOpen]);

    const deleteTable = useCallback((tableId: string | undefined) => {
        axios.delete(`/api/admin/tables/${tableId}`, {
            headers: {...getAuthHeaders()}
        })
            .then(() => {
                message.success('Job done!');
                refetch();
            })
            .catch(() => {
                message.error('Failed to complete work, please try again.');
            });
    }, [refetch]);

    const {data: games, isLoading: isLoadingGames} = useQuery(
        ['all-games'],
        () => axios.get<object, AxiosResponse<GameDto[]>>(`/api/admin/games`, {
            headers: {...getAuthHeaders()}
        }).then(r => r.data),
    );

    return (
        <Guarded>
            <Layout style={{padding: '2rem', minHeight: '100vh'}}>
                <Button style={{marginBottom: '1rem'}} onClick={() => navigate('/admin')}>Go back to administration.</Button>
                <List
                    size={"small"}
                    loading={isLoadingGames || isLoading}
                    header={<AddButton onSave={createNewTable} />}
                    dataSource={(data || [])}
                    renderItem={(table) =>
                        <>
                            <Descriptions
                                size={"small"}
                                bordered
                                layout={"vertical"}
                                title={<Typography.Text strong>{table.title}</Typography.Text>}
                                style={{maxWidth: '100vw'}}
                            >
                                <DescriptionsItem label={<Typography.Text strong>Description</Typography.Text>} >
                                    <Typography.Paragraph ellipsis={{
                                        tooltip: 'click to expand',
                                        expandable: true,
                                    }}>
                                        {table.description}
                                    </Typography.Paragraph>
                                </DescriptionsItem>
                                <DescriptionsItem label={<Typography.Text strong>Game</Typography.Text>} >
                                    <Typography.Text>{(games || []).find(game => game.id === table.gameId)?.title}</Typography.Text>
                                </DescriptionsItem>
                                <DescriptionsItem label={<Typography.Text strong>Media</Typography.Text>} >
                                    {!!table.mediaUrl ? <Flex vertical={false} justify={"center"}><Image src={table.mediaUrl} alt={'table-image'}/></Flex> : <>N/A</>}
                                </DescriptionsItem>
                                <DescriptionsItem label={<Typography.Text strong>Facilitator</Typography.Text>} >
                                    <Typography.Text>{table.facilitator}</Typography.Text>
                                </DescriptionsItem>
                                <DescriptionsItem label={<Typography.Text strong>Seats</Typography.Text>} >
                                    <Typography.Text>{`${table.seatsMin} - ${table.seatsMax}`}</Typography.Text>
                                </DescriptionsItem>
                                <DescriptionsItem label={<Typography.Text strong>Position</Typography.Text>} >
                                    <Typography.Text>{table.position}</Typography.Text>
                                </DescriptionsItem>
                                <DescriptionsItem label={<Typography.Text strong>Actions</Typography.Text>} >
                                    <>
                                        <Button type={"link"} onClick={() => editTable(table)}>Edit</Button>
                                        <Popconfirm
                                            title="Delete table and all reservations"
                                            description="Are you sure to delete this table and all reservations on it?"
                                            onConfirm={() => deleteTable(table.id)}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button type={"link"} danger>Delete</Button>
                                        </Popconfirm>
                                    </>
                                </DescriptionsItem>
                            </Descriptions>
                            <Divider />
                        </>
                    }
                />
                <Modal
                    destroyOnClose={true}
                    open={isModalOpen}
                    closable={false}
                    onCancel={() => {
                        form.resetFields();
                        setIsModalOpen(false);
                    }}
                    onOk={form.submit}>
                    <Typography.Title level={3}>Table Editor</Typography.Title>
                    <p>
                        <Typography.Text>Please enter the details below:</Typography.Text>
                    </p>
                    <Form form={form} {...defaultFormItemLayout} onFinish={submitChanges}>
                        <Form.Item
                            name="title"
                            label={"Title"}
                            required={true}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="description"
                            label={"Description"}
                            required={false}
                        >
                            <TextArea />
                        </Form.Item>
                        <Form.Item
                            name="gameId"
                            label={"Game"}
                            required={true}
                        >
                            <GameSelector allGames={games} loading={isLoadingGames} />
                        </Form.Item>
                        <Form.Item
                            name="facilitator"
                            label={"Facilitator"}
                            required={true}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="seatsMin"
                            label={"Min Players"}
                            required={true}
                        >
                            <InputNumber />
                        </Form.Item>
                        <Form.Item
                            name="seatsMax"
                            label={"Max Players"}
                            required={true}
                        >
                            <InputNumber />
                        </Form.Item>
                        <Form.Item
                            name="position"
                            label={"Position"}
                            required={false}
                        >
                            <InputNumber />
                        </Form.Item>
                        <Form.Item
                            name="mediaUrl"
                            label="Media (URL)"
                            required={false}
                        >
                            <Input />
                        </Form.Item>
                    </Form>
                </Modal>
            </Layout>
        </Guarded>
    )
}