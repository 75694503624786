import React, {useCallback, useEffect, useState} from 'react';
import {useQuery} from "react-query";
import axios, {AxiosResponse} from "axios";
import {getAuth, getAuthHeaders} from "../admin/auth";
import {Button, Descriptions, Divider, Form, Image, Input, List, message, Modal, Popconfirm, Typography} from "antd";
import {EventDTO, ExistsDto} from "./types";
import AddButton from "../commons/AddButton";
import moment from "moment";
import {useForm} from "antd/lib/form/Form";
import TextArea from "antd/es/input/TextArea";
import {defaultFormItemLayout} from "../commons/forms";
import dayjs from "dayjs";
import {Link} from "react-router-dom";
import DescriptionsItem from "antd/es/descriptions/Item";
import {MobileDatePicker} from "../commons/MobileDatePicker";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";

export const EventsEditor: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [form] = useForm<EventDTO | undefined>(undefined);
    const [editableEvent, setEditableEvent] = useState<EventDTO | undefined>(undefined);
    const {isLoading, data, refetch} = useQuery('lodge-admin-events',
        () => axios.get<object, AxiosResponse<EventDTO[]>>(`/api/admin/events`, {
            headers: {...getAuthHeaders()}
        }).then(r => r.data), {
            enabled: (!!getAuth() && !!getAuth().token)
        }
    )

    useEffect(() => {
        if (!!editableEvent) {
            form.setFieldsValue({...editableEvent, startsAt: dayjs(editableEvent.startsAt)});
        }
    }, [editableEvent, form]);

    const submitChanges = useCallback((data: EventDTO) => {
        const toSend = {...data, ...{
            startsAt: data.startsAt.valueOf()
        }}
        const request = (!editableEvent)
            ? () => axios.post(`/api/admin/events`, toSend, {
                headers: {...getAuthHeaders()}
            })
            : () => axios.put(`/api/admin/events/${editableEvent?.id}`, toSend, {headers: {...getAuthHeaders()}});
        request()
            .then(() => {
                setEditableEvent(undefined);
                form.resetFields();
                setIsModalOpen(false);
                message.success('Job done!');
                refetch();
            })
            .catch(() => {
               message.error('Failed to complete work, please try again.');
               setEditableEvent(undefined);
            });
    }, [form, editableEvent, setEditableEvent, refetch]);

    const editEvent = useCallback((event: EventDTO) => {
        setEditableEvent(event);
        setIsModalOpen(true);
    }, [setEditableEvent, setIsModalOpen]);

    const cancelEvent = useCallback((event: EventDTO) => {
        axios.delete(`/api/admin/events/${event.id}`, {
            headers: {...getAuthHeaders()}
        })
        .then(() => {
            message.success('Event canceled!');
            refetch();
        })
        .catch(() => {
            message.error('Could not cancel. Please try again or refresh.')
        })
    }, [refetch]);

    const publishEvent = useCallback((event: EventDTO) => {
        axios.put(`/api/admin/events/${event.id}/access`, undefined,{
            headers: {...getAuthHeaders()}
        })
        .then(() => {
            message.success('Event published!');
            refetch();
        })
        .catch(() => {
            message.error('Could not publish. Please try again or refresh.')
        })
    }, [refetch]);

    const createNewEvent = useCallback(() => {
        setEditableEvent(undefined);
        setIsModalOpen(true);
    }, [setEditableEvent, setIsModalOpen]);

    const downloadAttendanceSheet = useCallback((event: EventDTO) => {
        axios.get(`/api/admin/reservations/${event.id}/attendance-sheet`, {
            responseType: "blob",
            headers: {...getAuthHeaders()}
        })
            .then(r => {
                const fileName = r.headers['x-reservation-file-id'] || `${event.title}-attendance-sheet.pdf`;
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(r.data);
                link.download=fileName;
                link.click();
            })
            .catch(e => {
                const messsage = e?.response?.status === 400
                    ? e?.message || 'Something went wrong, please try again later or contact RPG Lodge directly!'
                    : 'Something went wrong, please try again later or contact RPG Lodge directly!';
                Modal.error({
                    title: 'Attendance sheet cannot be downloaded.',
                    content: messsage
                });
            })
    }, []);

    const validateTag = useCallback((tag: string, existingId: string | undefined) => {
        if (!tag) {
            return false;
        }
        return axios.get<object, AxiosResponse<ExistsDto>>(`/api/admin/events/exists/${tag}`, {
            headers: {
                ...getAuthHeaders()
            }
        })
        .then((r) => {
          const response = r.data;
          return !response.exists ||
              response.exists && !!existingId && existingId === response.entityId;
        })
    }, [getAuthHeaders])

    return (
      <>
          <Modal
              destroyOnClose={true}
              open={isModalOpen}
              closable={false}
              onCancel={() => {
                  form.resetFields();
                  setIsModalOpen(false);
              }}
              onOk={form.submit}>
              <Typography.Title level={3}>Event Editor</Typography.Title>
              <p>
                  <Typography.Text>Please enter the details below:</Typography.Text>
              </p>
              <Form form={form} {...defaultFormItemLayout} onFinish={submitChanges}>
                  <Form.Item
                      name="title"
                      label={"Title"}
                      required={true}
                  >
                      <Input />
                  </Form.Item>
                  <Form.Item
                      name="tag"
                      label={"Tag"}
                      required={true}
                      hasFeedback={true}
                      validateDebounce={1000}
                      rules={[
                          {
                              validator: async (rule, value) => {
                                  const isValid = await validateTag(value, editableEvent?.id);
                                  if (isValid) {
                                      return Promise.resolve();
                                  } else {
                                      return Promise.reject(new Error('Tag already exists.'));
                                  }

                              }
                          },
                          {
                              pattern: new RegExp(/^[a-zA-Z0-9-]+$/),
                              message: 'invalid tag'
                          },
                          {min:8},
                          {max:12},
                          {required: true}
                      ]}
                  >
                      <Input placeholder={"Use only letters, numbers and the '-' symbol."} max={12} min={5} />
                  </Form.Item>
                  <Form.Item
                      name="startsAt"
                      label={"When"}
                      required={true}
                  >
                      <MobileDatePicker />
                  </Form.Item>
                  <Form.Item
                      name="location"
                      label={"Location"}
                      required={true}
                  >
                      <Input />
                  </Form.Item>
                  <Form.Item
                      name="navigationLink"
                      label={"Navigation Link"}
                      required={false}
                  >
                      <Input />
                  </Form.Item>
                  <Form.Item
                      name="description"
                      label={"Description"}
                      required={false}
                  >
                      <TextArea maxLength={500} />
                  </Form.Item>
                  <Form.Item
                        name="mediaUrl"
                        label="Media (URL)"
                        required={false}
                  >
                      <Input />
                  </Form.Item>
              </Form>
          </Modal>
          <List
            loading={isLoading}
            header={<AddButton onSave={createNewEvent} />}
            size={"small"}
            dataSource={(data || [])}
            renderItem={(event: EventDTO) =>
                <>
                    <Descriptions size={"small"} bordered title={event.title} layout={"vertical"} >
                        <DescriptionsItem label={<Typography.Text strong>Description</Typography.Text>}>
                            <Typography.Paragraph>{event.description}</Typography.Paragraph>
                        </DescriptionsItem>
                        <DescriptionsItem label={<Typography.Text strong>Published</Typography.Text>}>
                            {event.published ? <CheckOutlined style={{color: 'green'}}/> : <CloseOutlined style={{color: 'red'}}/>}
                        </DescriptionsItem>
                        <DescriptionsItem label={<Typography.Text strong>Location</Typography.Text>}>
                            <Typography.Text>{event.location}</Typography.Text>
                        </DescriptionsItem>
                        <DescriptionsItem label={<Typography.Text strong>Navigation Link</Typography.Text>}>
                            {!!event.navigationLink ? <a href={event.navigationLink}>Navigation link</a> : <></>}
                        </DescriptionsItem>
                        <DescriptionsItem label={<Typography.Text strong>Starts At</Typography.Text>}>
                            <Typography.Text>{moment(event.startsAt as any).format('YYYY-MM-DD HH:mm')}</Typography.Text>
                        </DescriptionsItem>
                        <DescriptionsItem label={<Typography.Text strong>Event Media</Typography.Text>}>
                            {!!event.mediaUrl ? <div style={{maxWidth: '100px'}}><Image src={event.mediaUrl} alt={'event media'} /></div> : <></>}
                        </DescriptionsItem>
                        <DescriptionsItem label={<Typography.Text strong>Reservation Link</Typography.Text>}>
                            <Link to={`/reserve/${event.tag}`}>Reservation Link</Link>
                        </DescriptionsItem>
                        <DescriptionsItem label={<Typography.Text strong>Actions</Typography.Text>}>
                            <>
                                <Button type={"link"} onClick={() => editEvent(event)}>Edit</Button>
                                <Link style={{color: 'green'}} to={`/admin/tables/${event.id}`}>Tables</Link>
                                <Button type={"link"} onClick={() => downloadAttendanceSheet(event)}>Attendance Sheet</Button>
                                <Popconfirm
                                    title={`Are you sure you want to cancel ${event.title} completely?`}
                                    onConfirm={() => {
                                        cancelEvent(event);
                                    }}>
                                    <Button type={"link"} style={{color: 'red'}} >Cancel</Button>
                                </Popconfirm>
                                {!event.published &&
                                    <Popconfirm
                                        title={`Are you sure you want to publish ${event.title}? This cannot be undone.`}
                                        onConfirm={() => {
                                            publishEvent(event);
                                        }}>
                                        <Button type={"link"} style={{color: 'orange'}}>Publish</Button>
                                    </Popconfirm>
                                }
                            </>
                        </DescriptionsItem>
                    </Descriptions>
                    <Divider />
                </>
            }
          />
      </>
    );
}