import React, {PropsWithChildren, useEffect} from 'react';
import {hasValidSession, invalidateAuth} from './auth';
import {useNavigate} from "react-router";

export const Guarded: React.FC<PropsWithChildren> = (props) => {
    const {children} = props;
    const navigate = useNavigate();

    useEffect(() => {
        if (!hasValidSession()) {
            invalidateAuth();
            navigate('/admin/login');
        }
    });

    return (
        <>
            {children}
        </>
    )
}