import {FormProps} from "antd";

export const defaultFormItemLayout: FormProps = {
    labelCol: {
        xs: 24,
        sm: 24,
        md: 24,
        lg: 4,
        xl: 6,
        xxl: 6,
    },
    wrapperCol: {
        xs: 24,
        sm: 24,
        md: 24,
        lg: 20,
        xl: 18,
        xxl: 18,
    },
};