import React, {PropsWithChildren} from 'react';
import {Flex, Result, Spin} from "antd";
import {Label} from "./translations/Label";

export const LoadingIndicator: React.FC = () => {
    return (
        <Result icon={<></>} title={
            <Flex vertical={false} justify={"left"}>
                <Spin style={{width:'30%'}}/>
                <Label trl={'common.loading'} />
            </Flex>
        } />
    )
}

export const ErrorIndicator: React.FC = () => {
    return (
        <Result status={"error"}>
            <Label trl={'common.failure'} />
        </Result>
    )
}

export interface PendingDataGuardProps extends PropsWithChildren {
    isLoading: boolean | undefined;
    isError?: boolean | undefined;
    data: object | undefined;
}
export const PendingDataGuard: React.FC<PendingDataGuardProps> = (props) => {
    const {isLoading, isError, data} = props;
    return (
        <>
            {isLoading && !isError &&
                <LoadingIndicator />
            }
            {isError &&
                <ErrorIndicator />
            }
            {!!data && props.children}
        </>
    )
}