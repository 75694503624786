import React, {useState} from 'react';
import {PublicTableDTO} from "../home/types";
import {FileDoneOutlined} from "@ant-design/icons";
import {Button} from "antd";
import {useTranslation} from "react-i18next";
import {ReservationForm} from "./ReservationForm";

export type ReservationButtonProps = {
    table: PublicTableDTO;
}

export const ReservationButton: React.FC<ReservationButtonProps> = (props) => {
    const {table} = props;
    const {t} = useTranslation();
    const [engaged, setEngaged] = useState<boolean>(false);

    if (!engaged) {
        return (
            <Button type={"primary"} style={{width: '100%'}}
                    icon={<FileDoneOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />}
                    onClick={() => setEngaged(true)}
            >
                {t('reservation.action')}
            </Button>
        )
    }

    return (
        <ReservationForm table={table} />
    )
}