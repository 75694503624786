import React from 'react';
import {Guarded} from "./Guarded";
import {Button, Layout, Tabs} from 'antd';
import {useNavigate} from "react-router";
import {EventsEditor} from "../events/EventsEditor";
import {Reservations} from "../reservation/Reservations";
import {GamesAdministration} from "../games/GamesAdministration";
import {GameFamiliesAdministration} from "../games/GameFamiliesAdministration";

export const Administration: React.FC = () => {

    const navigate = useNavigate();

    return(
      <Guarded>
          <Layout style={{padding: '2rem', minHeight: '100vh'}}>
              <Button style={{marginBottom: '1rem'}} onClick={() => navigate('/')}>Go to main page.</Button>
              <Tabs
                  size={"small"}
                  defaultActiveKey="1"
                  type={"card"}
                  items={[
                      {
                          key: '1',
                          label: 'Events',
                          children: <EventsEditor />
                      },{
                          key: '2',
                          label: 'Reservations',
                          children: <Reservations />
                      },{
                          key: '3',
                          label: 'Games',
                          children: <GamesAdministration />
                      },{
                          key: '4',
                          label: 'Families',
                          children: <GameFamiliesAdministration />
                      }
                  ]}
              />
          </Layout>
      </Guarded>
    );
}