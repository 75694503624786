import moment from "moment";

// 4 hours in ms
const EXPIRATION = 4 * 60 * 60 * 1000;
const AUTH_KEY = 'lodge-key';

type Auth = {
    token: string;
    time: number;
}

export const hasValidSession: () => boolean = () => {
    const auth = sessionStorage.getItem(AUTH_KEY);
    return !!auth && (JSON.parse(auth) as any as Auth).time + EXPIRATION > (moment().utc().valueOf());
}

export const invalidateAuth: () => void = () => {
    sessionStorage.removeItem(AUTH_KEY)
}

export const startAuth: (token: string) => void = (token: string) => {
    const auth: Auth = {
        token,
        time: moment().utc().valueOf()
    }
    sessionStorage.setItem(AUTH_KEY, JSON.stringify(auth));
}

export const getAuth: () => Auth = () => {
    return JSON.parse(sessionStorage.getItem(AUTH_KEY)!) as any as Auth
}

export const getAuthHeaders: () => any = () => {
    return {
        'Authorization': `Basic ${getAuth().token}`
    }
}